import React, {Component} from 'react';
import './Section.scss';
import PropTypes from 'prop-types';

export default class Section extends Component {
  constructor (props) {
    super(props);
  }

  render () {
    return (
      <div className='container'>
          {this.props.sectionTitle && (
              <p className='title'>
                  {'\u2014\u2004' + this.props.sectionTitle + '\u2004\u2014'}
              </p>
          )}
        <div className='content'>
          {this.props.imgSrc && (
            <div className='cropImage'>
              <img src={this.props.imgSrc} className='image'/>
            </div>
          )}
      
          <div className='sectionDescription'>
            {this.props.sectionDescription}
          </div>
        </div>
      </div>
    );
  };
}

Section.propTypes = {
  sectionTitle : PropTypes.string,
  imgSrc : PropTypes.string,
  sectionDescription : PropTypes.any
}
