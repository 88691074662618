import React from 'react';
import './App.css';
import MainContainer from "./containers/MainContainer/MainContainer";
import Navigation from "./components/Navigation/Navigation";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Weather from "./components/Projects/ProjectItems/Weather";
import Energy from "./components/Projects/ProjectItems/Energy";
import Eventer from "./components/Projects/ProjectItems/Eventer";

function App() {
  return (
      <Router>
        {/*<Navigation/>*/}
        <Switch>
          <Route exact path="/" component={MainContainer}/>
          <Route path="/projects/Current-Weather" component={Weather}/>
          <Route path="/projects/Design-of-An-Energy-Visualization-Tool" component={Energy}/>
          <Route path="/projects/Eventr:-Simple-Scheduling-Solutions" component={Eventer}/>
        </Switch>
      </Router>
  );
}

export default App;
