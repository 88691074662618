import React, {Component} from 'react';
import './Studios.scss';
import Section from '../Section/Section';
import ScrollableSection from 'react-update-url-on-scroll';

const strings = {
    TITLE: 'db Studios',
    DESCRIPTION: 'A collection of digital art and photographs created with Photoshop and Pixelmator.',
    FETCHING: 'Fetching posts...'
};

export default class Studios extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            error : null,
            posts: [],
        };
    };

    componentDidMount() {
        this.fetchBlog();
    }

    fetchBlog = () => {
        const apiKey = 'lSHJeGWnTDRuJSzARaz7IK2aqpguoOkS0AD1GNaw73Ct2uJ15z';
        fetch(`https://api.tumblr.com/v2/blog/db-studios.tumblr.com/posts?api_key=${apiKey}&tag=new-web`)
            .then(data => {
                return data.json();
            })
            .then(json => {
                const posts = json.response.posts;
                this.setState({posts, isLoading: false});
            })
            .catch(error => {
                this.setState({error: 'Something went wrong. Please refresh or try again later.', isLoading: false})
            });
    };

    render() {
        const { posts, error } = this.state;
        const isLoading = false;
        return (
            //<ScrollableSection name={'db-studios'} meta={{title: 'db Studios'}}>
            <div className='studiosContainer'>
                <Section
                    sectionTitle={strings.TITLE}
                    sectionDescription={strings.DESCRIPTION}
                />
                <div className='postsContainer'>
                    {isLoading && (
                        <span>
                            <div className='loader' />
                            <p>{strings.FETCHING}</p>
                        </span>
                    )}
                    {posts && !isLoading && (
                        <div className='postSection'>
                            {posts.map((post, index) => {
                                return (
                                    <div className='photoContainer' key={index}>
                                        <img src={post.photos[0].original_size.url}/>
                                        <div className='overlay'>
                                            <div className='imgInfo'>
                                                {post.summary}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    )}
                </div>
                <img className='divider' src="https://lh3.googleusercontent.com/MfPxUrhxO1ikihW0n6aHHJmB0I4LDgr6Keovfqz0Z6-SSaPCyybTPahtdH_d2WeUl9kT3488YI8cbjWu-3-Fy7Jw0L-t4zKdM1ep9URMI7NDMlhXad1DwYCgbj3IsdOiB-BxeEmTJw=w2400"/>
            </div>
        //</ScrollableSection>
        );
    }
}