import React, {Component} from 'react';
import './About.scss';
import Section from '../Section/Section';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faGithub, faInstagram, faSpotify } from '@fortawesome/free-brands-svg-icons';
import ScrollableSection, {configureAnchors} from 'react-update-url-on-scroll';

const strings = {
    ABOUT : {
        TITLE : 'Hey there!',
        DESCRIPTION : 'I\'m Disha, and I currently work as a software developer for IBM Watson. I\'m interested in full stack development as well as UI/UX. When I\'m not programming, I enjoy building playlists on Spotify, graphic designing, and eating my way through whatever city I\'m in.',
        PAST_ROLES :'In the past, I have been:',
        INTERN_1: 'IT Summer Analyst - Accenture @ Cisco',
        INTERN_2: 'Website Intern - UC Davis Dheem Tana',
        INTERN_3: 'Technology Consulting Intern - Accenture @ C-IV',
        DESC_2: 'Find out more by clicking one of the buttons below!'
    }
};

export default class About extends Component {

    componentWillMount() {
        configureAnchors({offset: 54});
    }

    aboutInfo =
        (<div>
            <p className="About-info">{strings.ABOUT.DESCRIPTION}</p>

            <p className="About-info">{strings.ABOUT.PAST_ROLES}</p>
            <span className="About-info">
      <p className="About-intern">{strings.ABOUT.INTERN_1}</p>
      <p className="About-intern">{strings.ABOUT.INTERN_2}</p>
      <p className="About-intern">{strings.ABOUT.INTERN_3}</p>
    </span>
            <p className="About-info">{strings.ABOUT.DESC_2}</p>
            <div className='social__icons'>
                <a href="https://www.linkedin.com/in/dishabendre/" target="_blank"><FontAwesomeIcon id="linkedIn" icon={faLinkedin} size='2x' /></a>
                <a href="https://github.com/dbendre" target="_blank"><FontAwesomeIcon id="github" icon={faGithub} size='2x' /></a>
                <a href="https://www.instagram.com/youngdeesh/" target="_blank"><FontAwesomeIcon id="instagram" icon={faInstagram} size='2x' /></a>
                <a href="https://open.spotify.com/playlist/5ANTLKfcxocei3yugUT7Ax?si=m3Ptx7wDRZWE2-GfeiUTkQ" target="_blank"><FontAwesomeIcon id="spotify" icon={faSpotify} size='2x'/></a>
                <a href="mailto:dishabendre@gmail.com" target="_blank"><FontAwesomeIcon id="email" icon={faEnvelope} size='2x'/></a>
            </div>
        </div>);

    render () {
        return (
            //<ScrollableSection name={'about'} meta={{title: 'About'}}>
            <div className='aboutContainer'>
                <Section
                    sectionTitle={strings.ABOUT.TITLE}
                    sectionDescription={this.aboutInfo}
                />
                <img className='divider' src="https://lh3.googleusercontent.com/MfPxUrhxO1ikihW0n6aHHJmB0I4LDgr6Keovfqz0Z6-SSaPCyybTPahtdH_d2WeUl9kT3488YI8cbjWu-3-Fy7Jw0L-t4zKdM1ep9URMI7NDMlhXad1DwYCgbj3IsdOiB-BxeEmTJw=w2400"/>
            </div>
            //</ScrollableSection>
        );
    }
}
