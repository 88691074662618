import React, {Component} from 'react';
import './Navigation.scss';
import PropTypes from 'prop-types';

const strings = {
    HOME: 'Home',
    ABOUT: 'About',
    PROJECTS: 'Projects',
    STUDIOS: 'db Studios',
    RESUME: 'R\u00E9sum\u00E9' // unsure if need to escape
};


export default class Navigation extends Component {
    constructor (props) {
        super(props);
        this.state = {
            scrollClass : ''
        };
    }

    handleScroll = () => {
        if (document.getElementsByClassName('landing__headings') && document.getElementsByClassName('landing__headings')[0]) {
            const landingHeading = document.getElementsByClassName('landing__headings');
            if (window.scrollY > (landingHeading[0].scrollHeight - 70)) {
                this.setState({scrollClass : 'fixedScroll'});
            } else {
                this.setState({scrollClass : ''});
            }
        }
    };

    scrollListeners = () => {
        this.handleScroll();
    };

    componentDidMount() {
        window.addEventListener('scroll', this.scrollListeners);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollListeners)
    }

    scrollToElem = (tabName) => {
        if (tabName === strings.HOME) {
            (document.getElementsByClassName('Landing__container'))[0].scrollIntoView({behavior: 'smooth', block: 'start'});
        } else if (tabName === strings.ABOUT) {
            (document.getElementsByClassName('aboutContainer'))[0].scrollIntoView({behavior: 'smooth', block: 'start'});
        } else if (tabName === strings.PROJECTS) {
            (document.getElementsByClassName('projectsContainer'))[0].scrollIntoView({behavior: 'smooth', block: 'start'});
        } else if (tabName === strings.RESUME) {
            (document.getElementsByClassName('resumeContainer'))[0].scrollIntoView({behavior: 'smooth', block: 'start'});
        } else if (tabName === strings.STUDIOS) {
            (document.getElementsByClassName('studiosContainer'))[0].scrollIntoView({behavior: 'smooth', block: 'start'});
        }
    };

    render () {
        return (
            <div className={`nav-container ${this.state.scrollClass}`}>
                <nav className='navigation'>
                    <ul className='nav-item home' onClick={() => this.scrollToElem(strings.HOME)}>{strings.HOME}</ul>
                    <ul className='nav-item about' onClick={() => this.scrollToElem(strings.ABOUT)}>{strings.ABOUT}</ul>
                    <ul className='nav-item projects' onClick={() => this.scrollToElem(strings.PROJECTS)}>{strings.PROJECTS}</ul>
                    <ul className='nav-item dbStudios' onClick={() => this.scrollToElem(strings.STUDIOS)}>{strings.STUDIOS}</ul>
                    <ul className='nav-item' onClick={() => this.scrollToElem(strings.RESUME)}>{strings.RESUME}</ul>
                </nav>
            </div>
        );
    }
}