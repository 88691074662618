import React, {Component} from 'react';
import './Weather.scss';
import CryptoJs from 'crypto-js';

const strings = {
    CURRENT: 'Current',
    FORECAST: 'Forecast',
    WEATHER_PROMPT: 'What\'s your weather?',
    WEATHER_HELPER_TEXT: 'Type in your City, State and hit ENTER',
    LOCATION_PLACEHOLDER: 'Cupertino, CA'
};

// oauth/ request info:
const url = 'https://weather-ydn-yql.media.yahoo.com/forecastrss';
const appId = 'oeLtla6s';
const clientId = 'dj0yJmk9UjVvRHFPSFhaVTByJmQ9WVdrOWIyVk1kR3hoTm5NbWNHbzlNQS0tJnM9Y29uc3VtZXJzZWNyZXQmc3Y9MCZ4PTBl';
const key = '9337a81c15843c9569dda7e499439b8b0b0b81b0';
const oauth = {
    'oauth_consumer_key' : clientId,
    'oauth_nonce' : Math.random().toString(36).substring(2),
    'oauth_signature_method': 'HMAC-SHA1',
    'oauth_timestamp': parseInt(new Date().getTime() / 1000).toString(),
    'oauth_version': '1.0'
};

const query = {'location': 'sunnyvale,ca', 'format': 'json'}; // working for city state location

const merged = {};

Object.assign(merged, query, oauth);

const mergedArr = Object.keys(merged).sort().map(key => {
    return [key + '=' + encodeURIComponent(merged[key])]
});

const signature_base_str = 'GET&' + encodeURIComponent(url) + '&' + encodeURIComponent(mergedArr.join('&'));
const compositeKey = encodeURIComponent(key) + '&';
const hash = CryptoJs.HmacSHA1(signature_base_str, compositeKey);
const signature = hash.toString(CryptoJs.enc.Base64);

oauth['oauth_signature'] = signature;

const auth_header = 'OAuth ' + Object.keys(oauth).map(function(k) {
    return [k + '="' + oauth[k] + '"'];
}).join(',');

const queryUrl = Object.keys(query).map(function(k) {
    return encodeURIComponent(k) + '=' + encodeURIComponent(query[k])
}).join('&');

export default class Weather extends Component {

    onEnter = (event) => {
        if (event.key === 'Enter') {
            debugger;
            this.fetchWeatherData(event.target.value);
        }
    };

    fetchWeatherData = (location) => {
        const requUrl = url + '?' + queryUrl;
        debugger;
        fetch(requUrl, {
            method: 'GET',
            // mode: 'no-cors',
            headers: {
                'X-Yahoo-App-Id' : appId,
                'Authorization': auth_header,
            }
        })
            .then(data => {
                console.log('data');
                console.log(data);
                return data.json();
            })
            .then(json => {
                console.log(json);
                const weatherData = json.response;
                this.setState({weatherData});
            })
            .catch(error => {
                console.log('an error');
                console.log(error);
            })
    };

    render () {
        return (
          <div className='weatherContainer'>
              <div className='weather-title'>
                  <span id='current'>{strings.CURRENT}</span>
                  <span id='forecast'>{strings.FORECAST}</span>
              </div>
              <div className='weather-input__container'>
                  <div className='weather-input__prompt'>
                      {strings.WEATHER_PROMPT}
                  </div>
                  <input type='text'
                         className='weather-input__field'
                         placeholder={strings.LOCATION_PLACEHOLDER}
                         autoFocus
                         onKeyUp={(e) => this.onEnter(e)}
                  />
                  <div className='weather-input__helper-text'>{strings.WEATHER_HELPER_TEXT}</div>
              </div>
          </div>
        );
    }
}
