import React, {Component} from 'react';

export default class Eventer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {match: {params}} = this.props; // note.. this doesn't exist for you
    }

    render () {
        return (
            <div className='eventrContainer'>
                <div>Eventr</div>
            </div>
        );
    }
}
