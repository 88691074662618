import React, {Component} from 'react';
import About from '../../components/About/About';
import Navigation from '../../components/Navigation/Navigation';
import Projects from '../../components/Projects/Projects';
import Landing from '../../components/Landing/Landing';
import Resume from '../../components/Resume/Resume';
import Studios from '../../components/Studios/Studios';

export default class MainContainer extends Component {
    render () {
        return (
            <div className='mainContainer'>
                <Navigation/>
                <Landing/>
                <About/>
                <Projects/>
                <Studios />
                <Resume />
            </div>
        );
    }
};