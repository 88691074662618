import React, {Component} from 'react';
import './Resume.scss';
import ScrollableSection from 'react-update-url-on-scroll';


const strings = {
    RESUME: 'R\u00E9sum\u00E9',
    DOWNLOAD: 'Download on Google Docs'
};

export default class Resume extends Component {
    render() {
        return (
            //<ScrollableSection name={'resume'} meta={{title: Resume}}>
                <div>
                    <span className='resumeContainer'>
                        <img className='resumeImg' src='https://lh3.googleusercontent.com/Abd9fwCAqZjFx_8IcGtKHoJ_A7vd3dDaA4HguRFLXUusQO2tQMhS0sWalWuSJzMg_BpWtJ4bexsrNtoUSy42gx8EzbiLe0E5MviPpOPSOftU3GDf8I1DKprQWOvsXZ_oOyeSHYESog=w2400' />
                        <div className='downloadContainer'>
                            <span>
                                <a download='./websiteResume.jpg' target='_blank' href="https://drive.google.com/file/d/1d87oNcS_SW5VXzeuC4QvCqxGSis3pB_n/view?usp=sharing">{strings.DOWNLOAD}</a>
                            </span>
                        </div>
                    </span>
                    <img className='divider' src="https://lh3.googleusercontent.com/MfPxUrhxO1ikihW0n6aHHJmB0I4LDgr6Keovfqz0Z6-SSaPCyybTPahtdH_d2WeUl9kT3488YI8cbjWu-3-Fy7Jw0L-t4zKdM1ep9URMI7NDMlhXad1DwYCgbj3IsdOiB-BxeEmTJw=w2400"/>
                </div>
            //</ScrollableSection>
        );
    }
}