import React, {Component} from 'react';
import './Template.scss'
import {Link, Route} from 'react-router-dom';

export default class Template extends Component {

    sanitizeRoute = (route) => {
        return route.replace(/ /g, '-');
    };

    render () {
        return (
            <div className={`projects ${this.props.project}`}>
                <div className='description__container'>
                    <span className='project__headings'>
                        <h1 className='project__name'>
                            {/*<Link to={`/projects/${this.sanitizeRoute(this.props.projectName)}`}>*/}
                                {this.props.projectName}
                            {/*</Link>*/}
                        </h1>
                        <h1 className='em-dash'>{'\u2004\u2014'}</h1>
                    </span>
                    <span className='project__description'>
                        <p>{this.props.projectDescription}</p>
                    </span>
                </div>
                {/*<Link to={`/projects/${this.sanitizeRoute(this.props.projectName)}`}>*/}
                    <div className='project__image'>
                      <img src={this.props.imageSrc} />
                    </div>
                    {/*</Link>*/}
             </div>
        );
    }
}
