import React, {Component} from 'react';
import './Landing.scss';
import ScrollableSection from 'react-update-url-on-scroll';


export default class Landing extends Component {
    componentDidMount() {
        window.addEventListener('scroll', this.setHash);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.setHash);
    }

  scrollDown = () => {
    const about = document.getElementsByClassName('aboutContainer');
    about[0].scrollIntoView({behavior: 'smooth', block: 'start', alignToTop: false});
  };

  render() {
    return (
        //<ScrollableSection name={'home'} meta={{title: 'Home'}}>
          <div className='Landing__container'>
            <span>
              <img className='landing_img' src='https://lh3.googleusercontent.com/L6ejrGrg_BULPk9vjbQQLN6FofHprl0tUa4FAFnZ-7jEypYBEWh9Cq1NZGuHeQaA7o0dZ7EJSEfCXbJ0We5_PLOwfpKZ16ITM1ZALhGay2sqBnbHY4gEXzfqH03DDPtr5Ti2iNpMhA=w2400'/>
            </span>
            <div className='landing__headings'>
              <h1>&mdash; Disha Bendre &mdash;</h1>
              <h4>Developer. Music lover. Photo manipulator.</h4>
            </div>
            <div className='arrow__down' onClick={() => this.scrollDown()}></div>
          </div>
        //</ScrollableSection>
    )
  }
}
