import React, {Component} from 'react';
import './Projects.scss';
import Section from '../Section/Section';
import Template from './Template';
import ScrollableSection from 'react-update-url-on-scroll';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Weather from "./ProjectItems/Weather";


const strings = {
  SECTION_TITLE: 'Some things I\'ve worked on',
  PROJECT_INTRO: 'Here\'s a few things I\'ve worked on...',
  ENERGY_VIZ: 'Design of An Energy Visualization Tool',
  ENERGY_DESC: 'The Energy Data Visualization Tool was developed to allow Energy Conservation Office (ECO) staff at UC Davis to pull, analyze, and visualize Heating, Ventilation and Air Conditioning (HVAC) datasets in an efficient and intuitive manner. The application is used by ECO engineers and other technical users in the optimization of HVAC systems to identify operational issues and opportunities for energy efficiency upgrades.',
  CURR_WEATHER: 'Current Weather',
  CURR_WEATHER_DESC: 'A web application powered by the Yahoo! Weather API that displays weekly forecast based on zip code or city, state entry.',
  EVENTR: 'Eventr: Simple Scheduling Solutions',
  EVENTR_DESC: 'A prototyped app that aims to connect students with professional opportunities by consolidating information about company info sessions, interviews etc from the university job portal or emailing lists and outputting them as events on Google Calendar. 2nd place winner of the UC Davis Citris Mobile App Challenge.'
};

export default class Projects extends Component {
  constructor (props) {
    super(props);
  }

  render () {
    return (
        //<ScrollableSection name={'projects'} meta={{title: 'Projects'}}>
          <div className='projectsContainer'>
            <Section sectionTitle={strings.SECTION_TITLE} />
            <Template
              project='energy-viz'
              projectName={strings.ENERGY_VIZ}
              projectDescription={strings.ENERGY_DESC}
              imageSrc='https://lh3.googleusercontent.com/KTFxGK5H-IOhdYhRLbv6JNu_quJzpo8yiKtNdJyJNVlUljO4OGbS8pDna_hXRItR0LQdSO-GmfAa1piz5dxi1qTnAXSO2UTnW4GESC00x-jyL90V2MBEB7EgjpZKd2VoIlS9_6rIGA=w2400'
            />
          <Template
            project='current-weather'
            projectName={strings.CURR_WEATHER}
            projectDescription={strings.CURR_WEATHER_DESC}
            imageSrc='https://lh3.googleusercontent.com/SHrMQKyUntu_cnaasXZ140lRwvRFwNpCfFhTEtkk3yoCTl136GFaZAUuRY8709vgP5cLps1jQ1o-bTkNztYS-59qmUAfSQmSzWLoLXYYmo7dLX8Sy-dr3NkdI_ezls-sbVSy-Au4MQ=w2400'
          />
            <Template
                project='eventr'
                projectName={strings.EVENTR}
                projectDescription={strings.EVENTR_DESC}
                imageSrc='https://lh3.googleusercontent.com/pFoaoOSPt91wNNF1PenlnmBcW_YscPnNs_VaK0OdTFMSLpPQfVypUF23zX6x9zaQqrAjot5SFu_Jcnt8P4PjcLVOmhpRIAjVJO9pqGMahqNu-qNEXuKwsdmSXY2RpDqD-BkpmCL72g=w2400'
            />
            <img className='divider' src="https://lh3.googleusercontent.com/MfPxUrhxO1ikihW0n6aHHJmB0I4LDgr6Keovfqz0Z6-SSaPCyybTPahtdH_d2WeUl9kT3488YI8cbjWu-3-Fy7Jw0L-t4zKdM1ep9URMI7NDMlhXad1DwYCgbj3IsdOiB-BxeEmTJw=w2400"/>
          </div>
        // </ScrollableSection>
    );
  }
}
